export default {
    data() {
        return {
            
        }
    },
    methods: {
        mxCommonMapTextInfo(map, config, clickCallback, closeCallback) {
            if (!config || !config.text || !config.position) {
                return false
            }
            window.commonMapTextMarkers = window.commonMapTextMarkers || []
            // 默认全部关闭，同时只存在一个
            if (!config.neverRemove) {
                for (let i in window.commonMapTextMarkers) {
                    map.remove(window.commonMapTextMarkers[i])
                    window.commonMapTextMarkers.splice(i, 1)
                }
            }
            let text = new AMap.Text({
                bubble: config.bubble || false,
                title: config.title || "",
                text: config.text || "",
                topWhenClick: config.topWhenClick || false,
                draggable: config.draggable || false,
                anchor: config.anchor || "bottom-center",
                offset: config.offset || [0, -5],
                position: config.position,
                extData: config.extData || "",
                style: config.style || "",
                zIndex: config.zIndex || 999999
            })
            // 监听判断
            text.on("click", (event) => {
                let txt = event.target.dom.querySelector(".txtClose")
                let handleClick = (ev) => {
                    let isCloseClick = txt.contains(ev.target)
                    if (isCloseClick) {
                        closeCallback && closeCallback()
                        let index = window.commonMapTextMarkers.findIndex(x => x._amap_id === text._amap_id)
                        map.remove(text)
                        window.commonMapTextMarkers.splice(index, 1)
                    } else {
                        // 非关闭的地方执行事件
                        clickCallback && clickCallback()
                    }
                    document.removeEventListener("click", handleClick)
                }
                document.addEventListener("click", handleClick)
            })
            window.commonMapTextMarkers.push(text)
            map.add(text)
        },
        mxCloseCommonTextInfo(map) {
            window.commonMapTextMarkers && window.commonMapTextMarkers.length > 0 && window.commonMapTextMarkers.forEach(item => {
                map.remove(item)
            })
            window.commonMapTextMarkers = null
        },
        // 随机取颜色
        mxGetRandomColor() {
            let hex = Math.floor(Math.random() * 16777215).toString(16)
            while (hex.length < 6) {
              hex = '0' + hex
            }
            return '#' + hex
        }
    }
}

