export default {
  data () {
      return {
          createOrUpdate: ["create", "update"], // 当前是新增或者更新，弹窗分类
          weatherInfos: [ // 天气气象选择
            "晴", "晴转多云", "晴转雨", "多云", "多云转晴", "多云转阴", "多云转雨", "阴", "阴转多云", "阴转雨", "雾", "霾", 
            "阵雨", "雷阵雨", "雷阵雨伴有冰雹", "小雨", "中雨", "大雨", "暴雨", "冻雨", "雨夹雪",
            "阵雪", "小雪", "中雪", "大雪", "暴雪", 
            "浮尘", "浮尘转阴", "扬沙", "沙尘暴", "强沙尘暴"
          ],
          expWeathers: [ // 天气体验对于是否能骑行的感知
            "很好", "良好", "一般", "较差", "很差"
          ],
          serviceAreas: []
      }
  }
}

