import Layout from '@/views/admin/layout/Layout'

/**
* path: ''                       请使用全地址，例如：/system/user 或者 http:www.xxx.com 便于路由定位
* hidden: true                   设为true，则不会显示到菜单栏
* alwaysShow: true               设为true，则永远显示一级栏目
* redirect: noredirect           默认重定向，当没有找到地址时重定向的去向
* name:'router-name'             <keep-alive>需要使用 (must set!!!)
* meta : {
    title: 'title'               显示的名称
    icon: 'svg-name'             显示的图标
    roles: ['']                  权限
    isSkip: true                 如果菜单可以有下级且自己本身也需要打开页面，则配置isSkip
  }
  注意：如果有第三级及多级菜单，则path配全地址，“/path1/path2/...”
**/

const routerMap = [
  {
    path: '/',
    component: Layout,
    hidden: true,
    redirect: '/dashboard',
    meta: { title: '首页', icon: 'fa fa-gear' }, // , roles: ['System']
    children: [
      {
        path: '/dashboard',
        name: 'dashboard',
        component: () => import('@/views/admin/dashboard/index'),
        meta: { title: '首页', icon: 'fa fa-dashboard' }
      }
    ]
  },
  {
    path: '/baseData',
    name: 'baseData',
    alwaysShow: true,
    component: Layout,
    meta: { title: '基础数据', icon: 'fa fa-database', roles: ['BaseData.BaseData'] },
    redirect: '/analysis/user',
    children: [
      /* {
        path: '/analysis/imports',
        name: 'pageAnalysisImports',
        component: () => import('@/views/admin/analysis/imports'),
        meta: { isPageHead: true, parent: '基础数据', title: '导入数据', icon: 'fa fa-user', roles: ['BaseData.Import'] }
      }, */
      {
        path: '/analysis/multipleImports',
        name: 'pageAnalysisMultipleImports',
        component: () => import('@/views/admin/analysis/multipleImports'),
        meta: { isPageHead: true, parent: '基础数据', title: '导入数据', icon: 'fa fa-user', roles: ['BaseData.BaseData.Import'] }
      },
      {
        path: '/analysis/daily',
        name: 'pageAnalysisDaily',
        component: () => import('@/views/admin/analysis/daily'),
        meta: { isPageHead: true, parent: '基础数据', title: '每日统计数据', icon: 'fa fa-user', roles: ['BaseData.BaseData.View'] } 
      },
      {
        path: '/analysis/order',
        name: 'pageAnalysisOrder',
        component: () => import('@/views/admin/analysis/order'),
        meta: { isPageHead: true, parent: '基础数据', title: '订单数据', icon: 'fa fa-user', roles: ['BaseData.BaseData.View'] }
      },
      {
        path: '/analysis/user',
        name: 'pageAnalysisUser',
        component: () => import('@/views/admin/analysis/user'),
        meta: { isPageHead: true, parent: '基础数据', title: '用户数据', icon: 'fa fa-user', roles: ['BaseData.BaseData.View'] }
      },
      {
        path: '/analysis/vehicle',
        name: 'pageAnalysisVehicle',
        component: () => import('@/views/admin/analysis/vehicle'),
        meta: { isPageHead: true, parent: '基础数据', title: '车辆数据', icon: 'fa fa-user', roles: ['BaseData.BaseData.View'] }
      },
      {
        path: '/analysis/vehicleDaily',
        name: 'pageAnalysisVehicleDaily',
        component: () => import('@/views/admin/analysis/vehicleDaily'),
        meta: { isPageHead: true, parent: '基础数据', title: '车辆统计数据', icon: 'fa fa-user', roles: ['BaseData.BaseData.View'] }
      },
      {
        path: '/analysis/wallet',
        name: 'pageAnalysisWallet',
        component: () => import('@/views/admin/analysis/wallet'),
        meta: { isPageHead: true, parent: '基础数据', title: '钱包数据', icon: 'fa fa-user', roles: ['BaseData.BaseData.View'] }
      }
    ]
  },
  {
    path: '/analysis',
    name: 'analysis',
    alwaysShow: true,
    component: Layout,
    meta: { title: '数据分析', icon: 'fa fa-pie-chart', roles: ['DataAnalysis.Analysis', 'Operation.Operation'] },
    redirect: '/analysis/user',
    children: [
      {
        path: '/analysis/list',
        name: 'pageAnalysisList',
        component: () => import('@/views/admin/analysis/list'),
        meta: { isPageHead: true, parent: '数据分析', title: '数据报表', icon: 'fa fa-user', roles: ['DataAnalysis.Analysis.Dashboard'] }
      },
      {
        path: '/statistics/users',
        name: 'pageStatisticsUsers',
        component: () => import('@/views/admin/statistics/users'),
        meta: { isPageHead: true, parent: '数据分析', title: '用户统计图', icon: 'fa fa-user', roles: ['DataAnalysis.Analysis.User'] }
      },
      {
        path: '/statistics/vehicle',
        name: 'pageStatisticsVehicle',
        component: () => import('@/views/admin/statistics/vehicle'),
        meta: { isPageHead: true, parent: '数据分析', title: '车辆统计图', icon: 'fa fa-user', roles: ['DataAnalysis.Analysis.Vehicle'] }
      },
      {
        path: '/analysis/orderMap',
        name: 'pageOrderMap',
        component: () => import('@/views/admin/analysis/orderMap'),
        meta: { isPageHead: false, parent: '数据分析', title: '车辆热力图', icon: 'fa fa-user', roles: ['DataAnalysis.Analysis.VehicleHeatMap'] }
      },
      {
        path: '/analysis/deviceScreen',
        name: 'pageDeviceScreen',
        component: () => import('@/views/admin/analysis/deviceScreen'),
        meta: { isPageHead: false, parent: '数据分析', title: '实时车辆图', icon: 'fa fa-user', roles: ['DataAnalysis.Analysis.VehicleRealTimeAnalysis'] }
      },
      {
        path: '/analysis/intelligent',
        name: 'pageIntelligent',
        component: () => import('@/views/admin/analysis/intelligent'),
        meta: { isPageHead: false, parent: '数据分析', title: '智能调度', icon: 'fa fa-user', roles: ['DataAnalysis.Analysis.VehicleRealTimeAnalysis'] }
      },
      {
        path: '/analysis/movingRoute',
        name: 'pageMovingRoute',
        component: () => import('@/views/admin/analysis/movingRoute'),
        meta: { isPageHead: false, parent: '数据分析', title: '挪车路线', icon: 'fa fa-user', roles: ['Operation.Operation.MoveTask'] }
      },
      {
        path: '/analysis/orderLine',
        name: 'pageOrderLine',
        component: () => import('@/views/admin/analysis/orderLine'),
        meta: { isPageHead: false, parent: '数据分析', title: '订单轨迹', icon: 'fa fa-user', roles: ['Operation.Operation.MoveTask'] }
      }
    ]
  },
  {
    path: '/vehicles',
    name: 'vehicles',
    alwaysShow: true,
    component: Layout,
    meta: { title: '车辆分析', icon: 'fa fa-motorcycle', roles: ['DataAnalysis.VehicleAnalysis'] },
    redirect: '/vehicles/lowBattery',
    children: [
      {
        path: '/vehicles/lowBattery',
        name: 'pageVehiclesLowBattery',
        component: () => import('@/views/admin/vehicles/lowBattery'),
        meta: { isPageHead: true, parent: '车辆分析', title: '低电丢单', icon: 'fa fa-user', roles: ['DataAnalysis.VehicleAnalysis.LostOrderAnalysis', 'DataAnalysis.VehicleAnalysis.VehicleLogs'] }
      },
      {
        path: '/vehicles/lowBatteryStatistics',
        name: 'pageVehiclesLowBatteryStatistics',
        component: () => import('@/views/admin/vehicles/lowBatteryStatistics'),
        meta: { isPageHead: true, parent: '车辆分析', title: '低电丢单统计', icon: 'fa fa-user', roles: ['DataAnalysis.VehicleAnalysis.LostOrderAnalysis', 'DataAnalysis.VehicleAnalysis.VehicleLogs'] }
      },
      {
        path: '/vehicles/operation',
        name: 'pageVehiclesOperation',
        component: () => import('@/views/admin/vehicles/operation'),
        meta: { isPageHead: true, parent: '车辆分析', title: '运维丢单', icon: 'fa fa-user', roles: ['DataAnalysis.VehicleAnalysis.LostOrderAnalysis', 'DataAnalysis.VehicleAnalysis.VehicleLogs'] }
      },
      {
        path: '/vehicles/operationStatistics',
        name: 'pageVehiclesOperationStatistics',
        component: () => import('@/views/admin/vehicles/operationStatistics'),
        meta: { isPageHead: true, parent: '车辆分析', title: '运维丢单统计', icon: 'fa fa-user', roles: ['DataAnalysis.VehicleAnalysis.LostOrderAnalysis', 'DataAnalysis.VehicleAnalysis.VehicleLogs'] }
      },
      {
        path: '/vehicles/logs',
        name: 'pageVehiclesLogs',
        component: () => import('@/views/admin/vehicles/logs'),
        meta: { isPageHead: true, parent: '车辆分析', title: '车辆日志', icon: 'fa fa-user', roles: ['System.System.SystemConfig'] }
      }
    ]
  },
  {
    path: '/maintenance',
    name: 'maintenance',
    alwaysShow: true,
    component: Layout,
    meta: { title: '运维管理', icon: 'fa fa-tasks', roles: ['Operation.Operation'] },
    redirect: '/maintenance/user',
    children: [
      {
        path: '/maintenance/dayConfigs',
        name: 'pageDayWorkConfigs',
        component: () => import('@/views/admin/maintenance/dayConfigs'),
        meta: { isPageHead: true, parent: '运维管理', title: '工作时间配置', icon: 'fa fa-user', roles: ['Operation.Operation.EmployeeWorkConfig'] }
      },
      {
        path: '/maintenance/dayWorks',
        name: 'pageMaintenanceDayWorks',
        component: () => import('@/views/admin/maintenance/dayWorks'),
        meta: { isPageHead: true, parent: '运维管理', title: '员工工作纪录', icon: 'fa fa-user', roles: ['Operation.Operation.EmployeeWork'] }
      },
      {
        path: '/maintenance/dayStatistics',
        name: 'pageMaintenanceDayStatistics',
        component: () => import('@/views/admin/maintenance/dayStatistics'),
        meta: { isPageHead: true, parent: '运维管理', title: '员工工作统计', icon: 'fa fa-user', roles: ['Operation.Operation.EmployeeWork'] }
      },
      {
        path: '/maintenance/employee',
        name: 'pageMaintenanceEmployee',
        component: () => import('@/views/admin/maintenance/employee'),
        meta: { isPageHead: true, parent: '运维管理', title: '员工挪车统计', icon: 'fa fa-user', roles: ['Operation.Operation.MoveTask'] }
      },
      {
        path: '/maintenance/moveData',
        name: 'pageMaintenanceMoveData',
        component: () => import('@/views/admin/maintenance/moveData'),
        meta: { isPageHead: false, parent: '运维管理', title: '挪车数据', icon: 'fa fa-user', roles: ['Operation.Operation.MoveTask'] }
      },
      {
        path: '/maintenance/zoneStatistics',
        name: 'pageOrderZoneStatistics',
        component: () => import('@/views/admin/maintenance/zoneStatistics'),
        meta: { isPageHead: true, parent: '运维管理', title: '对账统计', icon: 'fa fa-user', roles: ['Operation.Operation.CustomerArea'] }
      },
      {
        path: '/maintenance/travelBill',
        name: 'pageTravelBill',
        component: () => import('@/views/admin/maintenance/travelBill'),
        meta: { isPageHead: true, parent: '运维管理', title: '行程单', icon: 'fa fa-user', roles: ['Operation.Operation.Travel'] }
      },
      {
        path: '/maintenance/travelBillDetail',
        name: 'pageTravelBillDetail',
        hidden: true,
        component: () => import('@/views/admin/maintenance/travelBillDetail'),
        meta: { isPageHead: true, parent: '运维管理', title: '行程单详情', icon: 'fa fa-user', roles: ['Operation.Operation.Travel'] }
      },
      {
        path: '/maintenance/vehicleStatistics',
        name: 'pageVehicleStatistics',
        component: () => import('@/views/admin/maintenance/vehicleStatistics'),
        meta: { isPageHead: true, parent: '运维管理', title: '车辆统计', icon: 'fa fa-user', roles: ['Operation.Operation.Travel'] }
      },
      /* {
        path: '/maintenance/test',
        name: 'pageTest',
        component: () => import('@/views/admin/maintenance/test'),
        meta: { isPageHead: true, parent: '数据分析', title: '订单核对', icon: 'fa fa-user', roles: ['Operation.Operation.MoveTask'] }
      }
      {
        path: '/maintenance/zoneStatisticsTest',
        name: 'pageOrderZoneStatisticsTest',
        component: () => import('@/views/admin/maintenance/zoneStatisticsTest'),
        meta: { isPageHead: true, parent: '数据分析', title: '对账统计Test', icon: 'fa fa-user', roles: ['Operation.Operation.MoveTask'] }
      } */
    ]
  },
  {
    path: '/system',
    name: 'system',
    component: Layout,
    meta: { title: '系统管理', icon: 'fa fa-gear', roles: ['System.System, AbpIdentity.Roles', 'AbpIdentity.Users'] },
    redirect: '/system/sysConfig',
    children: [
      {
        path: '/system/sysConfig',
        name: 'sysConfig',
        component: () => import('@/views/admin/system/sysConfig'),
        meta: { isPageHead: true, parent: '系统管理', title: '系统配置', icon: 'fa fa-users', roles: ['System.System.SystemConfig'] }
      },
      {
        path: '/system/areasManage',
        name: 'pageSystemAreasManage',
        component: () => import('@/views/admin/system/areasManage'),
        meta: { isPageHead: true, parent: '系统管理', title: '服务区配置', icon: 'fa fa-users', roles: ['System.System.SystemConfig'] }
      },
      {
        path: '/system/role',
        name: 'role',
        component: () => import('@/views/admin/system/role'),
        meta: { isPageHead: true, parent: '系统管理', title: '角色管理', icon: 'fa fa-users', roles: ['AbpIdentity.Roles'] }
      },
      {
        path: '/system/user',
        name: 'user',
        component: () => import('@/views/admin/system/user'),
        meta: { isPageHead: false, parent: '系统管理', title: '用户管理', icon: 'fa fa-user', roles: ['AbpIdentity.Users'] }
      },
      {
        path: '/system/loginLogs',
        name: 'loginLogs',
        hidden: true,
        component: () => import('@/views/admin/system/loginLogs'),
        meta: { isPageHead: true, parent: '系统管理', title: '登录日志', icon: 'fa fa-file-text-o', roles: ['System.System.SystemConfig'] }
      },
      {
        path: '/system/operationLogs',
        name: 'operationLogs',
        component: () => import('@/views/admin/system/operationLogs'),
        meta: { isPageHead: true, parent: '系统管理', title: '操作日志', icon: 'fa fa-book', roles: ['System.System.AuditLog'] }
      },
      {
        path: '/system/testDecode',
        name: 'pageTestDecode',
        component: () => import('@/views/admin/system/testDecode'),
        meta: { isPageHead: true, parent: '系统管理', title: '数据解析工具', icon: 'fa fa-book', roles: ['System.System.SystemConfig'] }
      }
    ]
  },
  {
    path: '/notice',
    name: 'notice',
    component: Layout,
    meta: { title: '文章管理', icon: 'fa fa-gear', roles: ['System'] },
    redirect: '/notice/list',
    children: [
      {
        path: '/notice/list',
        name: 'noticeList',
        component: () => import('@/views/admin/notice/list'),
        meta: { isPageHead: true, parent: '文章管理', title: '新闻中心', icon: 'fa fa-book', roles: ['System'] }
      }
    ]
  },
  /* {
    path: '/data',
    name: 'data',
    component: Layout,
    meta: { title: '数据统计', icon: 'fa fa-user', roles: ['Analysis.ReportView']},
    redirect: '/data/list',
    children: [
      {
        path: '/data/basic',
        name: 'basic',
        component: () => import('@/views/admin/data/basic'),
        meta: { title: '基础数据', icon: 'fa fa-user', roles: ['System'] }
      },
      {
        path: '/data/keeping',
        name: 'keeping',
        component: () => import('@/views/admin/data/keeping'),
        meta: { title: '留存率', icon: 'fa fa-user', roles: ['System'] }
      },
      {
        path: '/data/lossNode',
        name: 'lossNode',
        component: () => import('@/views/admin/data/lossNode'),
        meta: { title: '流失节点统计', icon: 'fa fa-user', roles: ['System'] }
      },
      {
        path: '/data/online',
        name: 'online',
        component: () => import('@/views/admin/data/online'),
        meta: { title: '在线人数', icon: 'fa fa-user', roles: ['System'] }
      },
      {
        path: '/data/customs',
        name: 'customs',
        component: () => import('@/views/admin/data/customs'),
        meta: { title: '关卡统计', icon: 'fa fa-user', roles: ['System'] }
      }
    ]
  },
  {
    path: '/material',
    name: 'material',
    component: Layout,
    alwaysShow: true,
    meta: { title: '游戏数据', icon: 'fa fa-user', roles: ['System'] },
    redirect: '/material/weapon',
    children: [
      {
        path: '/material/weapon',
        name: 'weapon',
        component: () => import('@/views/admin/material/weapon'),
        meta: { title: '武器状态', icon: 'fa fa-user', roles: ['System'] }
      },
      {
        path: '/material/playingMain',
        name: 'playingMain',
        component: () => import('@/views/admin/material/playingMain'),
        meta: { title: '主线副本', icon: 'fa fa-user', roles: ['System'] }
      }
    ]
  } */
]

export default routerMap